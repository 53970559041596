body {
	font-family: 'Lato', sans-serif;
	font-size: 14px;}

  p {
    line-height: 25px;
    color: #777777;
}

h3.inner-page-title {
    margin: 0px;
    font-size: 35px;
font-weight: bold;
color: #242424;
    font-family: 'Signika', sans-serif;}


.price{
    font-size: 14px;
    color: #83ac2b;
}
h4.inner-page-subtitle {
    margin: 0px;
    font-size: 25px;
    font-weight: bold;
    color: #242424;
    font-family: 'Signika', sans-serif;
}

.ngx-gallery-thumbnails .ngx-gallery-thumbnail{
	border: 1px double #eee !important;
}

.ngx-gallery-thumbnails .ngx-gallery-thumbnail.ngx-gallery-active{
    border: 1px double #96bc78 !important;
}

/* Third Button */

.add-to-cart-btn #button-3 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.add-to-cart-btn #button-3 a {
  position: relative;
  transition: all .45s ease-Out;
}

.add-to-cart-btn #circle {
  width: 0%;
  height: 0%;
  opacity: 0;
  line-height: 40px;
  border-radius: 50%;
  background: #f3f3f3;
  position: absolute;
  transition: all .5s ease-Out;
  top: 20px;
  left: 70px;
}

.add-to-cart-btn #button-3:hover #circle {
  width: 200%;
  height: 500%;
  opacity: 1;
  top: -70px;
  left: -70px;
}

.add-to-cart-btn #button-3:hover a {
  color: #83ac2b;
}
.add-to-cart-btn .button {
  display: inline-flex;
  height: 46px;
  width: 135px;
  color: #BFC0C0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
      background-color: #83ac2b;
    border-radius: 29px;
}


.add-to-cart-btn a {
    font-size: 14px;
    letter-spacing: 0px;
    color: #fff;
    text-decoration: none;

}


.textinn-banner {position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);}

.textinn-banner h2 {
    font-size: 68px;
    color: #fff;
    font-weight: bold;
    font-family: 'Signika', sans-serif;
}


.testi_desc {
    padding: 15px;
    text-align: justify;
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h4.inner-page-subtitle {
    margin: 0px;
    font-size: 25px;
    font-weight: bold;
    color: #83ac2b;
    font-family: 'Signika', sans-serif;
    margin-top: 6px;
    margin-bottom: 20px;
}

.chip p {
    /* color: #ff6600; */
    color: #333;
}

section.terms-detail {
    margin: 50px 0;
}

/* Vijay css */
.candidate{
  padding-top:80px;
  padding-bottom: 80px;
  background-color: #f2f4f5;
}
.candidate-form h1{
    font-size: 48px;
    font-weight: bold;
    color: #242424;
    font-family: 'Signika', sans-serif;
}

.btn-block{
    font-size: 16px;
    font-weight: 500;
    background-color: #83ac2b;
    border-radius: 0px;
    color: #fff;
    text-transform: uppercase;
}
/* End */

/* Mohit add Css */

.content-info {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 0 10px 0;
    align-items: center;
}

.content-name {
    color: #777;
}

.content-data {
    font-size: 12px;
}

.nutrition_heading {
	background-color: #fff;
	color: #83ac2b;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 0px;
	box-shadow: inherit;
	padding-top: 5px;
	padding-bottom: 10px;
	font-family: 'Signika', sans-serif;
}

.textinn-heading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    color: #fff;
    font-weight: bold;
    font-family: 'Signika', sans-serif;
}

.textinn-heading h2 {
    font-size: 68px;
}

.home-banner .owl-nav,.main-pri .owl-nav,.myblogsowl .owl-nav,.main-testimonial .owl-nav,.relatedowl .owl-nav,.core_prod .owl-nav{
	position: absolute;
	top: 50%;
	width: 100%;
}

.home-banner,.main-pri,.myblogsowl,.main-testimonial,.relatedowl,.core_prod {
    position: relative;
}

.home-banner .owl-prev {
    left: 150px;
    position: absolute;
	padding: 10px 15px 10px 15px !important;
	border-radius: 50px !important;
	background: #fed698 !important;

}

.main-pri .owl-prev{
	left: -30px;
    position: absolute;
	color: #000 !important;
}
.main-pri .owl-next{
	right: -30px;
    position: absolute;
	color: #000 !important;
}

.relatedowl .owl-prev {
	left: -30px;
    position: absolute;
	background: transparent !important;
	color: #000 !important;
}

.relatedowl .owl-next{
	right: -30px;
    position: absolute;
	background: transparent !important;
	color: #000 !important;
}

.core_prod .owl-prev {
	left: -30px;
    position: absolute;
	color: #000 !important;
}

.core_prod .owl-next{
	right: -30px;
    position: absolute;
	color: #000 !important;
}

.myblogsowl .owl-prev,.main-testimonial .owl-prev{
    left: -30px;
    position: absolute;
	color: #000 !important;

}

.myblogsowl .owl-next,.main-testimonial .owl-next{
	right: -30px;
    position: absolute;
	color: #000 !important;
}



.home-banner .owl-next {
    position: absolute;
    right: 150px;
	padding: 10px 15px 10px 15px !important;
	border-radius: 50px !important;
	background: #fed698 !important;

}

.home-banner i,.main-pri i,.relatedowl i{
	font-size: 25px;
}

.myblogsowl .owl-nav i{
	font-size: 25px;
}


/* End */

/* Shagufta Css */
ul.check {
    list-style: none;
    padding-left: 0;

}
ul.check li {
    position: relative;
    padding-left: 2rem;
    line-height: 1.5rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}
ul.check li:before {
    content: "\f00c";
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    position: absolute;
    left: 0;
    font-size: .8rem;
    color: #3f3d3d;
    background-color: hsla(0,0%,100%,.9215686274509803);
    border-radius: 100%;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* End */

.ngx-slider .ngx-slider-selection {
    background: #81ae2b !important;
}

.ngx-slider .ngx-slider-pointer {
    background-color: #82ad2b !important;
}

.core_prod img{
	cursor:pointer;
}


.navbar .megamenu{ padding: 1rem; }

/* ============ desktop view ============ */
@media (min-width: 768px){
	.navbar-expand-md .navbar-nav .dropdown-menu {
	    position: fixed !important;
	}
}
@media all and (min-width: 992px) {

  .navbar .megamenu{
	  left: 0;
	  right: 0;
	  margin-top: 0;
	  max-width: 100%;
	  width: 100vw;
  }

}


/*  Mohit megha menu css  */

/* .has-megamenu{
	min-height: 50px;
} */

.menu_options ul {
    list-style: none;
    padding: 0;
    min-width: 300px;
}

.menu_options ul li a {
    color: #333;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1.3px;
    font-weight: 600;
}

.menu_options ul li {
    margin: 10px 0;
}

.menu_options .sub-sub-menu a {
    text-transform: inherit;
    color: #848484;
    font-size: 14px;
    font-weight: normal;
}


.sub-menu li a:hover{
	color: #83ac2b;
}

.wd-sub-menu li a .menu-label {
	position: absolute;
	color: #fff;
	background-color: #FBBC34;
	height: 15px;
	font-size: 10px;
	padding: 10px 15px;
	margin-top: -17px;
}
.menu-label-orange {
    background-color: #FBBC34;
}
.menu-label-orange:before {
    border-color: #FBBC34 !important;
}
.menu-label:before {
	content: "";
    position: absolute;
    top: 100%;
    left: 6px;
    border: 11px solid;
    border-right-color: transparent!important;
    border-bottom-color: transparent!important;
    border-right-width: 7px;
    border-left-width: 0;
}

.megamenu{
	display: inline-block;
    transition: all 500ms;
    opacity: 0;
    transform: translateY(35px) translateZ(0);
    visibility: hidden;
}
.dropdown:hover .megamenu{
	opacity: 1;
	transform: translateY(4px) !important;
	visibility: visible !important;
}

.detail p {
    color: #000;
    line-height: 30px;
    font-size: 18px;
}

/* End */

.two_columm{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.download .elementor-image img{
    padding: 10px;
}


.product_bg_image {
	height: 400px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: #fff;
}

.prim-left-img img {
    max-height: 500px;
    width: auto!important;
	display: inline-block !important;
}

/* Our service Css */
.inner-banner {
    position: relative;
}
.inner-bnner-text {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    text-align: center;
}
.inner-bnner-text h1 {
    font-size: 3.2rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}
.inner-bnner-text h1 span {
    font-size: 2.6rem;
    font-weight: 400;
}

.bounce {
    animation: bounce 2s infinite;
}
.arrow {
    text-align: center;
    margin: 4% 0;
}

.ser-full {
    background-image: url('./assets/images/retail-4.4aecbd5af63a5c48554f.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.content-bottom{
    background: hsla(0,0%,100%,.8784313725490196);
    padding: 4rem;
    width: 45rem;
    margin: 4rem auto;
    position: relative;
    box-shadow: 10px 10px 0 hsl(0deg 0% 100% / 46%);
}

.dash h2:before {
    right: inherit!important;
}
.my_service_page h2:before {
    content: "";
    background: #81ae2b;
    width: 4rem;
    height: .2rem;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.my_service_page h2 {
    font-size: 1.6rem;
    color: #000;
    font-weight: 600;
    position: relative;
    display: inline-block;
    padding-bottom: .5rem;
    text-transform: uppercase;
}

.my_service_page a{
	text-decoration: none;

}

.my_service_page a:hover{
	color: #fff;
}

.environment{
    left: -160px;
    padding: 2rem;
    background: #fff;
    position: relative;
    box-shadow: 0 0 30px rgb(0 0 0 / 30%);
}

.leftser{
    margin: 5rem 0;
}

.se-left img {
    height: 41rem;
    object-fit: cover;
}

.build-brand {
    background-image: url('./assets/images/branding-3.2bf2d57df2764c62de9e.jpg');
}

.dev{
    background-image: url('./assets/images/dev.ca64034661956548e0a3.jpg');
}

.more, p.blog-date {
    color: #fff;
}
.more {
    font-size: .9rem;
    background-color: #82ad2b;
    padding: .9rem 2rem;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5rem;
    position: relative;
    overflow: hidden;
}

/* End */

/* Quick View Css */
.quickview .modal-dialog {
    margin-top: 15%;
}
/* End  */


.fsd-sec ul li {
    flex: 0 0 18%;
}
.fsd-sec li img {
    width: 27%;
}
.quote-btn {
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.quote-btn #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: #83ac2b;
    position: absolute;
    transition: all .5s ease-Out;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
}
.quote-btn a:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%,-50%);
}
.quote-btn:hover a {
    color: #FFFFFF;
    /*border: 2px solid #83ac2b;*/
    background: #83ac2b;
}
.quote-btn span{
    position: relative;
    z-index: 99;
}
.quote-btn a {
    transition: all .5s ease-Out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: inline-flex;
    height: 46px;
    width: fit-content;
    padding: 0px 20px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: .8em;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 29px;
    font-weight: 700;
    color: #83ac2b;
    border: 2px solid #83ac2b;
}
.product-quote-wrap {
    display: block;
    border: 1px solid rgb(131 172 43 / 50%);
    padding: 20px;
    border-radius: 7px;
    background: #f9f9f9;
}
.owl-carousel .owl-item .testi_desc p img {
    width: auto;
}
.search-wrap {
    position: absolute;
    right: 0;
    width: 320px;
    visibility: hidden;
    opacity: 0;
    top: 48px;
    z-index: -1;
    transition: 300ms;
}
.search-wrap.showsearch {
    visibility: visible;
    opacity: 1;
    top: 82px;
    z-index: 9999;
}
.input-wrap {
    width: 100%;
    background: #FFF;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
}
input.search-input {
    padding: 15px 10px;
    border: 0;
    width: 100%;    
    background: #f0f0f0;
    outline: 0;
}
button.search-btn {
    border: 0;
    background: #83ac2b;
    color: #FFF;
    padding: 12px 16px;
    font-size: 1.125rem;
    outline: 0;
}
.goog-te-gadget {
    position: absolute;
    right: 350px;
    overflow: hidden;
    height: 30px;
    top: 29px;
    z-index: 999;
    width: 95px;
}
.goog-te-gadget .goog-te-combo {
    width: 100%;
}
.cipot-product {
    margin-top: 50px;
}
.bred-crumbs-pro li:last-child a {
    cursor: auto;
}
a.brand_name {
    cursor: auto;
}
.main-eric span {
    text-transform: capitalize;
}
.bred-crumbs-pro li:last-child a:before {
    display: none;
}
.blog-excerpt p {
    margin: 0;
}
.mobile-version-wrap, .navbar-side.mobile-menu{
    display: none;
}

@media only screen and (min-width: 1399px) {

}
@media only screen and (min-width: 1399px) {

}
@media only screen and (min-width: 450px) and (max-width: 768px) {
    .product_bg_image {
        height: 200px;
    }
    a.text-decoration-none p {
        line-height: 20px;
    }
    .rat-text{
        padding-left: 10px;
    }
    .candidate-form h1 {
        font-size: 34px;
    }
    .cart_image img {
        height: 120px;
        width: auto;
    }
    .product-inntitle h2 {
        font-size: 24px;
    }
    .cart-btn{
        flex-wrap: wrap;
    }
    .quote-btn {
        margin-left: 0;
        margin-top: 10px;
    }
    section.page-info p, section.page-info li {
        font-size: 16px;
        
    }

}
@media only screen and (max-width: 768px) {
    .mobile-version-wrap button {
        border: 0;
        background: #83ac2b;
        color: #FFFFFF;
        padding: 0;
        outline: 0;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        border-radius: 3px;
    }
    header.main-head {
        padding: 30px 0 15px 0;
    }
    .home-banner .owl-prev {
        left: 0;
    }
    .home-banner .owl-next {
        right: 0;
    }
    .goog-te-gadget {
        right: 10px;
        top: 0;
        height: 22px;
    }
    .goog-te-gadget .goog-te-combo {
        font-size: 9px;
        border: 0px solid #000;
    }
    .mobile-version-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    header.main-head .inner-header .mobi_cart {
        display: flex;
        align-items: center;
    }
    header.main-head  .mobi_cart {
        display: flex;
        align-items: center;
    }

    header.main-head .inner-header .mobile-version-wrap .mobi_cart a {
        font-size: 12px!important;
        padding-left: 0!important;
        padding-right: 12px!important;
    }
    header.main-head .mobile-version-wrap .mobi_cart a {
        font-size: 12px!important;
        padding-left: 0!important;
        padding-right: 12px!important;
        color: #000!important;
    }

    .navbar-side.mobile-menu {
        position: fixed;
        z-index: 9999;
        background: #FFFFFF;
        width: 80%;
        top: 0;
        left: -90%;
        height: 100%;
        box-shadow: 10px 0px 20px rgb(0 0 0 / 10%);
        overflow-y: auto;
        display: block;
        transition: 500ms;
    }
    .navbar-side.mobile-menu.reveal {
        left: 0;
    }
    .navbar-side.mobile-menu .nav-link {
        padding: 0.5rem 1rem;
        color: #000000!important;
        font-size: 12px!important;
        width: 100%;
        padding-left: 1rem !important;
    }
    .sub-menu-wrap {
        padding-left: 30px;
    }
    h2.mobile-menu-eading {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 5px 0 0 0;
    }
    .close-sidebar-wrap a {
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        color: #83ac2b;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        border: 1px solid #83ac2b;
    }
    .close-sidebar-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 20px 0px 0;
    }
    nav.navbar.navbar-expand-md {
        display: none;
    }
    ul.user-dropdown-menu {
        list-style: none;
    }









}
@media only screen and (max-width: 450px) {
    .fsd-sec ul li {
        flex: 0 0 50%;
    }
    .product_bg_image {
        height: 250px;
    }
    .contant-pagtitle h2 {
        font-size: 32px;
    }
    .main-pri .owl-next, .myblogsowl .owl-next, .main-testimonial .owl-next {
       right: 0px;
    }
    .main-pri .owl-prev, .myblogsowl .owl-prev, .main-testimonial .owl-prev {
        left: 0px;
    }
    .main-pri .owl-nav {
        top: 45%;
    }
    .myblogsowl .owl-nav{
        top: 37%;
    }
    .testi_desc {
        max-height: 170px;
        overflow-y: auto;
        margin: 20px 0;
        min-height: unset;
    }
    .testimonial-text{
        min-height: 380px;
    }
    .wd-hover-base.hover-ready:hover .content-product-imagin, .wd-hover-base.hover-ready.state-hover .content-product-imagin {
       transform: scale(0.98);
    }
    .footer-logo img {
        width: 150px!important;
    }
    .cart-table td {
        border-bottom: 0!important;
    }
    .p-image img {
        height: 90px;
        width: auto!important;
    }
    ngx-gallery.ngx-gallery .ngx-gallery-layout {
        height: 500px;
    }
    .cart-btn {
        flex-wrap: wrap;
    }
    .quote-btn {
        margin-left: 0;
        margin-top: 10px;
    }
    .relatedowl .owl-next {
        right: 0;
    }
    .relatedowl .owl-prev {
        left: 0;
    }
    .contact-form .btnContact {
        width: 25%;
    }
    .chippeppers-title {
        flex: 0 0 100%;
    }
    .product-inntitle h2 {
        font-size: 28px;
    }
    .textinn-heading h2 {
        font-size: 42px;
    }
    .bred-crumbs-pro {
        margin-top: 10px;
    }
    .textinn-banner h2 {
        font-size: 38px;
    }
    .widget {
        margin: 20px 0 0;
    }
    .bred-crumbs-pro.innbred {
        display: none;
    }
    .candidate-form h1 {
        font-size: 32px;
    }
    a.dropdown-item {
        color: #000000;
        font-size: 14px;
        width: 100%;
        padding: 0.5rem 1rem;
    }
    section.page-info p, section.page-info li {
        font-size: 14px;
        line-height: 24px;
    }
}